<template>
	<div class="oh">
		<svg ref="svgContainer" fill="none" :viewBox="`0 0 ${width} ${height}`" @mousemove="handleMouseMove">
			<path v-for="path in walls" :key="path.name" :name="path.name" :d="path.d" :stroke="path.stroke" :stroke-width="path.strokeWidth"/>

			<g v-for="rect in seats" :key="rect.name">
				<rect
					:x="rect.x"
					:y="rect.y"
					:width="rect.width"
					:height="rect.height"
					:fill="getStatusColor(rect)"
					@click="$message.success(rect.name)"
					@mouseenter="hoverSea(rect)"
					@mouseleave="hoverSeat = null"/>
				<text :x="rect.x+10" :y="rect.y+20" fill="#333" font-size="20">{{rect.name}}</text>
			</g>
			<g name="老板">
				<rect x="2110" :y="wall" width="40" height="340" fill="#F8F6F0" @click="$message.success('壁架')"/>
				<rect x="2750" :y="wall" width="40" height="340" fill="#F8F6F0" @click="$message.success('壁架')"/>
				<rect x="2580" y="100" width="80" height="160" fill="#F8F6F0" @click="$message.success('茶桌')"/>
			</g>
			<g name="会议室">
				<rect x="1550" y="100" width="400" height="180" fill="#F8F6F0" @click="$message.success('会议桌')"/>
			</g>
			<g name="律师">
				<rect x="1060" :y="wall" width="30" height="200" fill="#F8F6F0" @click="$message.success('柜子')"/>
			</g>
			<g name="接待">
				<rect x="1010" :y="wall" width="30" height="290" fill="#F8F6F0" @click="$message.success('壁架')"/>
				<rect x="870" y="100" width="80" height="160" fill="#F8F6F0" @click="$message.success('茶几')"/>
			</g>
			<g name="多媒体">
				<rect :x="wall" :y="wall" width="160" height="470" fill="#F8F6F0" @click="$message.success('多媒体讲台')"/>
			</g>
			<image x="3000" y="800" width="300" xlink:href="@/assets/logo_slogan.png" opacity="0.5"/>
		</svg>
		<!-- 鼠标悬停提示 -->
		<div class="pa" :style="`pointer-events: none; top: ${tooltip_y}px; left: ${tooltip_x}px;`" v-if="hoverSeat">
			<div class="bcb7a06b p10 br5 lh1_5" style="box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);">
				<div class="df jcsb" style="min-width: 140px;">
					<div class="fs16">{{ hoverSeat.name }}</div>
					<div class="fs16" v-if="hoverSeat.employee">{{ hoverSeat.employee.name }} {{ genders[hoverSeat.employee.gender] || '' }}</div>
					<div class="fs16" v-else>空闲</div>
				</div>
				<template v-if="hoverSeat.assets && hoverSeat.assets.length">
					<div v-for="asset in hoverSeat.assets" :key="asset.id">
						<div class="fs14">{{asset.name}}</div>
						<div class="fs12">{{asset.no}}</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		employees: {
			type: Object
		},
		assets: {
			type: Object
		}
	},
	data() {
		return {
			width: 4200,
			height: 1100,
			tooltip_x: 0,
			tooltip_y: 0,
			hoverSeat: null,
			wall: 20,
			genders: {
				1: '男',
				2: '女',
			}
		};
	},
	computed: {
		// 墙壁
		walls () {
			const aisle = 370;
			const stroke = "#f1f1f1";
			const right = this.width;
			const bottom = this.height;
			const strokeWidth = this.wall;
			const wall1 = right - 700;
			const wall2 = right - 1400;
			const wall3 = right - 3500;
			return [
				{name: "多媒体左墙", d: "M0 500H710", strokeWidth, stroke},
				{name: "多媒体右墙", d: `M${wall3} 0V${aisle+(strokeWidth/2)}`, strokeWidth, stroke},
				{name: "后门房间后墙", d: `M350 ${bottom}V500`, strokeWidth, stroke},
				{name: "后门房间隔墙", d: "M350 800H700V600", strokeWidth, stroke},
				{name: "后门左墙", d: `M${wall3} ${bottom}V900`, strokeWidth, stroke},
				{name: "交付玻璃墙", d: `M${wall1+100} ${aisle}H${right}`, strokeWidth, stroke},
				{name: "市场玻璃墙", d: `M${wall1} 0V${aisle}H${wall1-250}`, strokeWidth, stroke},
				{name: "茶水间璃墙", d: `M${wall1-350} 0V${aisle}H${wall1-600}`, strokeWidth, stroke},
				{name: "老板右墙", d: `M${wall2} 0V${aisle}H2500`, strokeWidth, stroke},
				{name: "接待右墙", d: `M1050 0V${aisle}`, strokeWidth, stroke},
				{name: "接待玻璃墙", d: `M800 ${aisle}H1300`, strokeWidth, stroke},
				{name: "会议室左墙", d: `M1400 0V${aisle+(strokeWidth/2)}`, strokeWidth, stroke},
				{name: "会议室右墙", d: `M2100 0V${aisle}`, strokeWidth, stroke},
				{name: "会议室玻璃墙", d: `M1500 ${aisle}H2400`, strokeWidth, stroke},
				{name: "大门左墙", d: `M${wall2} ${bottom}V480`, strokeWidth, stroke},
				{name: "大门右墙", d: `M${wall1} ${bottom}V480`, strokeWidth, stroke},
				{name: "人事", d: "M2940 700V770H3360V490H2940V630", strokeWidth, stroke},
				{name: "围墙", d: `M${wall3} ${bottom}H0V0H${right}V${bottom}H${wall1-200}`, strokeWidth: 40, stroke},
				{name: "外墙", d: `M800 ${bottom}H${wall1-500}`, strokeWidth: 40, stroke}
			];
		},
		// 区域
		areas () {
			return [
				[
					{x: 2950, y: 500, width: 100, height: 60, qty: 4},
					{x: 2950, y: 700, width: 80, height: 60, qty: 4},
					{x: 2950, y: 730, width: 100, height: 30, qty: 4}
				], [
					{x: 3600, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 3660, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 3860, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 3920, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 4120, y: 480, width: 60, height: 120, qty: 5, vertical: true},
				], [
					{x: 3510, y: this.wall, width: 60, height: 120, qty: 5, gap: 70},
					{x: 3640, y: 240, width: 60, height: 120, qty: 4, gap: 70}
				], [
					{x: 3270, y: this.wall, width: 60, height: 140, qty: 2},
					{x: 3270, y: 140, width: 60, height: 140, qty: 2}
				], [
					{x: 2840, y: this.wall, width: 40, height: 60, qty: 4, vertical: true},
					{x: 2810, y: this.wall, width: 40, height: 60, qty: 4, vertical: true}
				], [
					{x: 2620, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 2560, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 2290, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 2230, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 1990, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 1930, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 1610, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 1670, y: 480, width: 60, height: 120, qty: 5, vertical: true},
					{x: 800, y: 480, width: 60, height: 100, qty: 6, vertical: true},
				], [
					{x: 480, y: 630, width: 60, height: 160, qty: 2}
				], [
					{x: 480, y: 920, width: 60, height: 160, qty: 2}
				], [
					{x: 1160, y: 20, width: 80, height: 180, qty: 1}
				], [
					{x: 2250, y: 80, width: 80, height: 200, qty: 1}
				]
			];
		},
		// 座位
		seats () {
			const seats = [];
			const areas = this.areas;
			const assets = this.assets || {};
			const employees = this.employees || {};
			const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
			for (let a in areas) {
				var num = 0;
				for (let area of areas[a]) {
					var { x, y, width, height, vertical, qty, gap } = area;
					var n_y = y;
					var n_x = x;
					for (var i = 0; i < qty; i++) {
						num++
						const name = `${alphabet[a]}${num}`;
						if (vertical) {
							n_y = y+((height + (gap || 0))*i);
						} else {
							n_x = x+((width + (gap || 0))*i);
						}
						seats.push({ x: n_x, y: n_y, width, height, name, employee: employees[name] || null, assets: assets[name] || [] });
					}
				}
			}
			return seats;
		}
	},
	methods: {
		// 状态颜色映射
		getStatusColor (s) {
			if (s.employee) return '#B7A06A';
			if (s.assets && s.assets.length) return '#E2D6BA';
			return '#F8F6F0';
		},
		hoverSea (s) {
			this.hoverSeat = s;
		},
		// 鼠标移动事件
		handleMouseMove (event) {
			// const svg = this.$refs.svgContainer;
			// const svgRect = svg.getBoundingClientRect();
			// // 获取 SVG 的 viewBox 属性
			// const viewBox = svg.viewBox.baseVal;
			// const viewBoxWidth = viewBox.width;
			// const viewBoxHeight = viewBox.height;
			// // 计算缩放比例
			// const scaleX = viewBoxWidth / svgRect.width;
			// const scaleY = viewBoxHeight / svgRect.height;
			// // 转换为 SVG 坐标系
			// const x = (event.clientX - svgRect.left) * scaleX;
			// const y = (event.clientY - svgRect.top) * scaleY;
			// // 调整提示框位置
			// this.tooltip_x = Math.min(x + 30, viewBoxWidth + 30);
			// this.tooltip_y = Math.min(y + 60, viewBoxHeight + 60);
			this.tooltip_x = event.clientX - 150;
			this.tooltip_y = event.clientY - 50;
			// console.log(event.clientX);
			// console.log(event.clientY);
		}
	}
};
</script>
