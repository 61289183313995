<template>
	<div ref="chart" style="width: 100%; height: 100%;"></div>
</template>


<script>
	import * as echarts from 'echarts';
	import chinaCity from "@/utils/chinaCity.json";

	export default {
		props: {
			dddd: Array
		},
		data() {
			return {
				echart: null
			}
		},
		watch: {
			dddd () {
				this.updChart()
			}
		},
		async mounted() {
			this.init();
			window.addEventListener('resize', this.echart.resize);
		},
		methods: {
			init () {
				// 注册地图数据
				echarts.registerMap('china', chinaCity);
				// 初始化 ECharts 实例并设置配置项
				this.echart = echarts.init(this.$refs['chart']);
				this.updChart();
			},
			updChart () {
				const data = this.dddd;
				var max = 1;
				for (let d of data) {
					if (max < d.value) {
						max = d.value;
					}
				}
				var option = {
					visualMap: {
						min: 0,
						max: max,
						left: 'left',
						top: 'bottom',
						text: ['高','低'],
						calculable: true
					},
					series: [
						{
							type: 'map',
							map: 'china',
							layoutCenter: ['50%', '70%'],
							layoutSize: '136%',
							data
						}
					]
				};
				this.echart.setOption(option);
			}
		},
		beforeDestroy() {
			this.echart.dispose();
			window.removeEventListener('resize', this.echart.resize);
		}
	}
</script>