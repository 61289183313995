<template>
	<div ref="chart" style="width: 100%; height: 100%;"></div>
</template>


<script>
	import * as echarts from 'echarts';

	export default {
		props: {
			dddd: Array
		},
		data() {
			return {
				echart: null
			}
		},
		watch: {
			dddd () {
				this.updChart()
			}
		},
		async mounted() {
			this.init();
			window.addEventListener('resize', this.echart.resize);
		},
		methods: {
			init () {
				this.echart = echarts.init(this.$refs['chart']);
				this.updChart();
			},
			updChart () {
				const option = {
					// title: {
					// 	text: '销售金额'
					// },
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						// left: 40,
						// right: 40,
						// bottom: 60,
						containLabel: false
					},
					legend: {
						// bottom: 0
					},
					dataset: {
						dimensions: this.dddd.length ? this.dddd[0].keys : [],
						source: this.dddd
					},
					xAxis: {
						type: 'category',
						boundaryGap: false
					},
					yAxis: {
						type: 'value'
					},
					series: [
						{ type: 'line', showSymbol: false, smooth: true },
						{ type: 'line', showSymbol: false, smooth: true },
						{ type: 'line', showSymbol: false, smooth: true }
					]
				};
				this.echart.setOption(option);
			}
		},
		beforeDestroy() {
			this.echart.dispose();
			window.removeEventListener('resize', this.echart.resize);
		}
	}
</script>