<template>
	<main-table-a class="oa navbar">
		<div slot="table">
			<div class="df f1">
				<div class="f5 df fdc">
					<div class="mb20 pr20">
						<div class="mb10 c6">人员概况</div>
						<div class="df br10 bcf8f6f0">
							<div class="p10 pl30 f1" style="min-width: 90px;" v-for="general in data.general" :key="general.name">
								<h5 class="c6 mb5">
									{{general.name}}
									<el-tooltip v-if="general.tooltip">
										<div slot="content" v-html="general.tooltip"></div>
										<i class="el-icon-question ch"></i>
									</el-tooltip>
								</h5>
								<p class="fs12"><b class="fs18 cb7a06b">{{general.value}}</b> 人</p>
							</div>
						</div>
					</div>
					<div class="df f1 mb20 oh">
						<div class="pr20 f1">
							<div class="mb10">
								<el-switch size="mini" v-model="params.map" active-value="birth" inactive-value="register" active-text="籍贯" inactive-text="户籍"></el-switch>
							</div>
							<map-chart class="br10 bcf8f6f0" style="width: 100%; height: 400px;" :dddd="data[params.map] || []"></map-chart>
						</div>
						<div class="pr20 f1">
							<div class="mb10">
								<el-date-picker size="mini" type="monthrange" v-model="params.date" :editable="false" :clearable="false" @change="getHome" value-format="yyyy-MM" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
							</div>
							<a-chart class="br10 bcf8f6f0" style="width: 100%; height: 400px;" :dddd="data.histories"></a-chart>
						</div>
					</div>
					<div class="df fww mb10 oh" ref="bottomPies">
						<e-chart class="f1" :style="'height: '+height+'px;'" v-for="(pie, p) in data.pie" :dddd="pie" :key="p"></e-chart>
					</div>
				</div>
				<div class="f2 df fdc">
					<div class="mb20">
						<div class="mb10 df jcsb">
							<div class="c6">待办提醒</div>
						</div>
						<div class="br10 oh">
							<div class="df fww bcf8f6f0">
								<div class="p10" style="width: 25%; min-width: 80px;" v-for="reminder in data.reminder" :key="reminder.name">
									<template v-if="reminder.name === '待签考勤'">
										<h5 class="c6 mb5">
											<span class="cp mr5" @click="$utils.toRoute(reminder.path)" v-if="reminder.path">{{reminder.name}}</span>
											<span class="mr5" v-else>{{reminder.name}}</span>
											<el-tooltip v-if="reminder.tooltip">
												<div slot="content" v-html="reminder.tooltip"></div>
												<i class="el-icon-question ch"></i>
											</el-tooltip>
										</h5>
										<div class="fs12">
											<b class="fs18 cb7a06b cp">{{reminder.value}}</b> 人
											<el-popconfirm title="确定通过 OA 要发起上月考勤签名通知吗？" v-if="$utils.checkAuth($api.URI_CHECKIN, 'inform') && reminder.value" @confirm="getCheckin({action: 'inform'})">
												<span slot="reference" class="c409eff cp">通知</span>
											</el-popconfirm>
										</div>
									</template>
									<template v-else>
										<h5 class="c6 mb5">
											<span class="cp mr5" @click="$utils.toRoute(reminder.path)" v-if="reminder.path">{{reminder.name}}</span>
											<span class="mr5" v-else>{{reminder.name}}</span>
											<el-tooltip v-if="reminder.tooltip">
												<div slot="content" v-html="reminder.tooltip"></div>
												<i class="el-icon-question ch"></i>
											</el-tooltip>
										</h5>
										<p class="fs12"><b class="fs18 cb7a06b">{{reminder.value}}</b> 人</p>
									</template>
								</div>
							</div>
							<div class="df aic jcsb p10 bce2d6ba">
								<div class="f1 tac c6" v-for="contract in data.contracts" :key="contract.name" @click="$utils.toRoute(contract.path)">
									<b class="fs18 cb7a06b cp">{{contract.value || 0}}</b>
									<h4 class="mt5 c3" v-if="contract.name == '用工合同'">
										用工合同
										<el-tooltip v-if="contract.tooltip">
											<div slot="content" v-html="contract.tooltip"></div>
											<i class="el-icon-question ch"></i>
										</el-tooltip>
									</h4>
									<h5 class="mt5" v-else>
										{{contract.name}}
										<el-tooltip v-if="contract.tooltip">
											<div slot="content" v-html="contract.tooltip"></div>
											<i class="el-icon-question ch"></i>
										</el-tooltip>
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div class="p20 br10 mb20 bcf8f6f0">
						<div class="df mb20 jcsb">
							<div>企业风险监控</div>
							<div class="fs12 c6 cp" @click="form_dialog = true">专业解决方案 <i class="el-icon-arrow-right"></i></div>
						</div>
						<div class="df aic pl20 cp" @click="risk_drawer = true">
							<div class="br_50" :style="`padding: 3px; border: 1px solid ${color.b};`">
								<div class="tac fs38 fw1 cff bcf1 br_50" :style="`width: 80px; line-height: 80px; background-image: linear-gradient(to bottom, ${color.b}, ${color.a}, ${color.b});`">
								<i class="el-icon-first-aid-kit"></i>
							</div>
							</div>
							<div class="f1 ml20">
								<div class="fwb mb10 c3">企业用工风险指数： <span :style="`color: ${color.a}`">{{color.t || '未知'}}</span></div>
								<div class="fs12 c9" v-if="color.tt">{{color.tt}}</div>
							</div>
						</div>
					</div>
					<div class="mb20 df fdc">
						<div class="mb10 c6">投保情况</div>
						<el-table size="mini" :data="data.insurance">
							<el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="value" label="参与人数" :formatter="({value}) => { return value ? `${value} 人` : '无' }" width="70"></el-table-column>
							<el-table-column prop="expire" label="即将到期" :formatter="({expire}) => { return expire ? `${expire} 人` : '无' }" width="70"></el-table-column>
							<el-table-column prop="expired" label="已到期" :formatter="({expired}) => { return expired ? `${expired} 人` : '无' }" width="70"></el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<seat class="oh" :employees="data.seats" :assets="data.assets" v-if="true"></seat>
		</div>
		<div slot="footer" class="p20"></div>
		<template slot="other">
			<el-drawer title="企业用工风险监测" :size="((theme.width > 680 ? (680/theme.width) : 1)*100)+'%'" :visible.sync="risk_drawer" @opened="startDetection" @closed="risks = []; progress = 0; total_score = 100;">
				<div class="df aic pl20 pr20" v-if="risks.length">
					<div :class="['risk_status', {risk_loading: progress < risks.length}]" :style="`border: 1px solid ${risk_color.b};`">
						<div class="risk_icon" :style="`background-image: linear-gradient(to bottom, ${risk_color.b}, ${risk_color.a}, ${risk_color.b});`">
							<i class="el-icon-first-aid-kit"></i>
						</div>
					</div>
					<div class="f1 ml20" v-if="progress < risks.length">
						<div class="fwb mb10 c3">正在进行检测： <span :style="`color: ${risk_color.a}`">{{risks[progress].title}}</span></div>
						<div class="fs12 c9">请内心等待</div>
					</div>
					<div class="f1 ml20" v-else>
						<div class="fwb mb10 c3">企业用工风险指数： <span :style="`color: ${risk_color.a}`">{{risk_color.t || '未知'}}</span></div>
						<div class="fs12 c9" v-if="risk_color.tt">{{risk_color.tt}}</div>
					</div>
					<el-button :type="risk_color.total_score < 90 ? 'danger' : 'warning'" @click="form_dialog = true" v-if="risk_color.total_score < 100">获取专业解决方案</el-button>
				</div>
				<div class="df aic pl20 pr20" v-else>
					<div class="risk_status risk_loading">
						<div class="risk_icon">
							<i class="el-icon-first-aid-kit"></i>
						</div>
					</div>
					<div class="f1 ml20">
						<div class="fwb mb10 c3">正在获取检测项目...</div>
						<div class="fs12 c9">请内心等待</div>
					</div>
				</div>
				<div class="p20" v-if="risks.length">
					<el-steps direction="vertical" :active="progress">
						<el-step v-for="(risk, r) in risks" :key="risk.title">
							<div class="df mb20 jcsb" slot="title">
								<div class="fs16">{{risk.title}}  <span class="fs12 cff br10" :style="`padding: 2px 10px; background-color: `+(risk.problems ? '#ff372d' : '#40de7c')" v-if="risk.subtitle">{{risk.subtitle}}</span></div>
								<div class="c9 fs14" v-if="risk.tips">{{risk.tips}}</div>
							</div>
							<div class="pb50" slot="description">
								<div class="tac dib cp" style="width: 120px;" v-for="(item, i) in risk.items" :key="item.name">
									<div @click="showRisk(item)" v-if="progress == r && (risk.items.length - 1) == i">
										<div class="dif aic jcc fs24 br_50 risk_item_loading" style="width: 40px; height: 40px; border: 1px solid #40de7c; color: #40de7c;">
											<i :class="item.icon"></i>
										</div>
										<div class="mt5 fs12">{{item.name}}</div>
									</div>
									<div @click="showRisk(item)" v-else-if="item.issue">
										<div class="dif aic jcc fs24 br_50" style="width: 40px; height: 40px; border: 1px solid #fe9983; color: #fe9983;">
											<i :class="item.icon"></i>
										</div>
										<div class="mt5 fs12" style="color: #fe9983;">{{item.name}}</div>
									</div>
									<div @click="showRisk(item)" v-else>
										<div class="dif aic jcc fs24 br_50" style="width: 40px; height: 40px; border: 1px solid #40de7c; color: #40de7c;">
											<i :class="item.icon"></i>
										</div>
										<div class="mt5 fs12">{{item.name}}</div>
									</div>
								</div>
							</div>
						</el-step>
					</el-steps>
				</div>
			</el-drawer>
			<el-dialog width="380px" title="获取企业用工风险防控方案" :visible.sync="form_dialog">
				<el-form label-width="80px" :model="formLabelAlign">
					<el-form-item label="姓名" prop="name">
						<el-input v-model="formLabelAlign.name" placeholder="请输入联系人姓名"></el-input>
					</el-form-item>
					<el-form-item label="职位" prop="name">
						<el-input v-model="formLabelAlign.name" placeholder="请输入联系人职位"></el-input>
					</el-form-item>
					<el-form-item label="行业" prop="name">
						<el-input v-model="formLabelAlign.name" placeholder="请输入企业行业"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="region">
						<el-input v-model="formLabelAlign.region" placeholder="请输入联系人手机号码"></el-input>
					</el-form-item>
					<el-form-item label="问题描述" prop="desc">
						<el-input type="textarea" v-model="formLabelAlign.desc" placeholder="请简单描述问题。我们将匹配安排专业老师为您解答处理"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="$message.error('正在努力接入中...')">立即获取</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog :title="risk.name" :width="((theme.width > 680 ? (680/theme.width) : 1)*100)+'%'" :visible.sync="risk_dialog">
				<div>
					<div class="mb20" v-for="item in risk.items" :key="item.title">
						<div class="mb10 fwb c33 fs16">{{item.title}}：</div>
						<div class="lh1_5 c9" v-html="item.content"></div>
					</div>
				</div>
			</el-dialog>
		</template>
	</main-table-a>
</template>


<style type="text/css">
	.risk_status {
		padding: 3px;
		border-radius: 50%;
		border: 1px solid #f1f1f1;
	}
	.risk_status .risk_icon {
		width: 60px;
		color: #fff;
		font-size: 28px;
		line-height: 60px;
		border-radius: 50%;
		text-align: center;
		background-color: #ccc;
	}
	.risk_loading {
		overflow: hidden;
		position: relative
	}
	.risk_loading::before {
		content: '';
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		background: conic-gradient(transparent, rgba(255, 255, 255, 0.6), transparent 30%);
		animation: rotate 3s linear infinite;
	}
	.risk_item_loading {
		position: relative
	}
	.risk_item_loading::before {
		content: '';
		position: absolute;
		width: 120%;
		height: 120%;
		border-radius: 50%;
		border: 1px solid #82eaa7;
		border-top: 1px solid #fff;
		animation: rotate 2s linear infinite;
	}
	@keyframes rotate {
		100% {
			-webkit-transform: rotate(1turn);
			-moz-transform: rotate(1turn);
			-ms-transform: rotate(1turn);
			-o-transform: rotate(1turn);
			transform: rotate(1turn);
		}
	}
</style>

<script>
	// import * as echarts from 'echarts';
	import moment from 'moment';
	import aChart from './chart-a';
	import eChart from './chart';
	import mapChart from './chart-map';
	import seat from './seat';
	// import chinaCity from "@/utils/chinaCity.json";
	import mainTableA from '@/components/main-table-a';
	import { mapState, mapMutations } from 'vuex';

	export default {
		components: {
			seat,
			aChart,
			eChart,
			mapChart,
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_permissions']),
			workbenchs () {
				const workbenchs = [];
				for (let p of this.admin_permissions) {
					if (p.path === '/') {
						workbenchs.push(p);
					}
				}
				return workbenchs;
			},
			color () {
				const  { colors } = this.data;
				return colors || {};
			}
		},
		data () {
			return {
				timer: null,
				risk_timer: null,
				height: 200,
				progress: 0,
				risk_drawer: false,
				risk_dialog: false,
				form_dialog: false,
				formLabelAlign: {},
				risk: {},
				risk_color: {},
				charts: [],
				risks: [],
				params: {
					action: 'hrm',
					map: 'register',
					date: [new Date(moment().subtract(6, 'months')), new Date()]
				},
				data: {}
			}
		},
		async mounted () {
			if (!this.$utils.checkAuth(this.$api.URI_HOME, this.params.action)) {
				return this.setWorkBench(this.workbenchs[0].action);
			}
			await this.getHome();
			this.timer = setInterval(() => { this.getHome(); }, 4000);
		},
		methods: {
			...mapMutations(['setWorkBench']),
			initHeight () {
				const { offsetWidth, offsetHeight } = this.$refs['bottomPies'];
				const { pie } = this.data;
				if ((offsetWidth/pie.length) < offsetHeight) {
					this.height = offsetWidth/pie.length;
				} else {
					this.height = offsetHeight;
				}
			},
			showRisk (r) {
				this.risk = r;
				this.risk_dialog = true;
			},
			setElementHeight (e, s = 1.4) {
				// 获取元素的宽度
				const { offsetWidth } = this.$refs[e];
				// 设置元素的高度
				this.$refs[e].style.height = `${offsetWidth / s}px`;
			},
			async getCheckin (params) {
				const res = await this.$http.get(this.$api.URI_CHECKIN, { params });
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
			},
			async startDetection () {
				this.risks = [];
				var total_score = 100;
				var total_problems = 0;
				await this.$utils.delay(1000);
				const res = await this.$http.get(this.$api.URI_HOME, { params: { action: 'risk' } });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				const { risks, levels } = result;
				this.risks = risks.map(r => {
					return { ...r, items: [] };
				});
				for (let j in risks) {
					const { items, score, delay } = risks[j];
					var problems = 0;
					for (var level of levels) {
						if (total_score <= level.score) {
							this.risk_color = {...level.color, t: level.name, tt: total_problems ? `风险点 ${total_problems} 个` : '', total_score };
						}
					}
					for (let i in items) {
						if (items[i].issue) {
							problems++;
						}
						this.risks[this.progress]['items'].push(items[i]);
						await this.$utils.delay(items[i].delay || delay);
						if (i == (items.length - 1)) {
							this.risks[this.progress]['problems'] = problems;
							this.risks[this.progress]['tips'] = problems ? '' : '企业管理到位，无此类风险';
							this.risks[this.progress]['subtitle'] = `共 ${items.length} 项`+(problems ? `，${problems} 项有问题` : '');
						}
					}
					await this.$utils.delay(delay || 2000);
					total_score = total_score - (score / items.length) * problems;
					total_problems = total_problems + problems;
					this.progress++;
				}
			}
		},
		beforeDestroy () {
			clearInterval(this.timer);
			this.timer = null;
		}
	}
</script>