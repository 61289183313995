<template>
	<main-table-a class="oa navbar">
		<div slot="table" class="df f1">
			<div class="f4 pr20">
				<div class="mb10">
					<div class="mb10 c6">仓储概况</div>
					<div class="df br5 bcf8f6f0">
						<div class="p10 f1" style="min-width: 90px;" v-for="general in data.general" :key="general.name">
							<h5 class="c6 mb5">
								{{general.name}}
								<el-tooltip v-if="general.tooltip">
									<div slot="content" v-html="general.tooltip"></div>
									<i class="el-icon-question ch"></i>
								</el-tooltip>
							</h5>
							<p class="fs12"><b class="fs18 cb7a06b">{{general.value}}</b></p>
						</div>
					</div>
				</div>
				<div class="mb10 c6">物料情况</div>
				<div class="p10 br5 df bcf8f6f0">
					<div class="f1">
						<div class="p5 df aic jcsb" v-for="material in data.materials" :key="material.name">
							<h5 class="c6">【{{material.warehouse_name}}】{{material.name}}</h5>
							<p class="fs12"><b class="fs18 cb7a06b">{{material.amount}}</b></p>
						</div>
					</div>
					<div class="f4" ref="pie" style="width: 100%; height: 320px;"></div>
				</div>
			</div>
			<div class="f1">
				<div class="mb20">
					<div class="mb10 c6">待办提醒</div>
					<div class="br5 p10 bcf8f6f0" v-for="reminder in data.reminder" :key="reminder.name">
						<div class="mb5 c6">{{reminder.name}}</div>
						<div class="df jcsb fww">
							<div class="p10 mb10 bce1d6ba" style="width: 48%;" v-for="item in reminder.item" :key="item.name">
								<h5 class="c6 mb5">{{item.name}}</h5>
								<p class="fs12"><b class="fs18 cb7a06b">{{item.value}}</b> 份</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import mainTableA from '@/components/main-table-a';
	import { mapState, mapMutations } from 'vuex';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_permissions']),
			workbenchs () {
				const workbenchs = [];
				for (let p of this.admin_permissions) {
					if (p.path === '/') {
						workbenchs.push(p);
					}
				}
				return workbenchs;
			}
		},
		data() {
			return {
				timer: null,
				charts: [],
				params: {
					action: 'wms'
				},
				data: {}
			}
		},
		async mounted() {
			if (!this.$utils.checkAuth(this.$api.URI_HOME, this.params.action)) {
				return this.setWorkBench(this.workbenchs[0].action);
			}
			await this.getHome();
			this.timer = setInterval(() => { this.getHome(); }, 4000);
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			...mapMutations(['setWorkBench']),
			initChart () {
				this.initPie('pie', this.data);
				this.charts['pie'].resize();
			},
			initPie (e, d) {
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					title: [
						{
							subtext: '物料类别',
							left: '20%',
							textAlign: 'center'
						}, {
							subtext: '品牌占比',
							left: '50%',
							textAlign: 'center'
						}, {
							subtext: '供应占比',
							left: '80%',
							textAlign: 'center'
						}
					],
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '50%',
							data: d.categories,
							right: '60%'
						}, {
							type: 'pie',
							radius: '50%',
							data: d.brands,
							right: 0
						}, {
							type: 'pie',
							radius: '50%',
							data: d.suppliers,
							left: '60%'
						}
					]
				};

				option && this.charts[e].setOption(option);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
			window.removeEventListener('resize', this.initChart);
			if (this.charts['pie']) {
				this.charts['pie'].dispose();
			}
		}
	}
</script>