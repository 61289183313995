<template>
	<div ref="chart" style="width: 100%; height: 100%;"></div>
</template>


<script>
	import * as echarts from 'echarts';

	export default {
		props: {
			dddd: Object
		},
		data() {
			return {
				echart: null
			}
		},
		watch: {
			dddd () {
				this.updChart()
			}
		},
		async mounted() {
			this.init();
			window.addEventListener('resize', this.echart.resize);
		},
		methods: {
			init () {
				this.echart = echarts.init(this.$refs['chart']);
				this.updChart();
			},
			updChart () {
				const { text, subtext, data } = this.dddd;
				const option = {
					title: {
						text,
						subtext,
						left: 'center',
						textStyle: {
							color: '#666',
							fontSize: 14
						},
						subtextStyle: {
							color: '#b7a06b',
							fontWeight: 900,
							fontSize: 18
						}
					},
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '78%',
							// top: subtext ? '15%' : '-10%',
							top: '5%',
							label: {
								show: false
							},
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							data
						}
					]
				};
				this.echart.setOption(option);
			}
		},
		beforeDestroy() {
			this.echart.dispose();
			window.removeEventListener('resize', this.echart.resize);
		}
	}
</script>