<template>
	<main-table-a class="oa navbar">
		<div slot="table" class="df f1">
			<div class="f1 pr20">
				<div class="mb20">
					<div class="mb10 c6">销售情况</div>
					<div class="p10 br5 bcf8f6f0">
						<div class="mb20">
							<div class="mb10 c6">今日</div>
							<div class="df">
								<div class="p10 f1 mr20 bce1d6ba">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">销量</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 单</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
								<div class="p10 f1 mr20 bce1d6ba">
									<div class="df aic jcsb">
										<h5 class="c6 mb5">环比</h5>
										<p class="fs12"><b class="cb7a06b">99</b> 单</p>
									</div>	
									<p class="fs12"><b class="fs18 cb7a06b">99</b> 元</p>
								</div>
							</div>
						</div>
						<div>
							<div ref="parstMap"></div>
							<div ref="partMap"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="f3 pr20">
				<div class="mb10 c6">交付分布</div>
				<div ref="registerMap" style="width: 100%;" class="br5 p20 bcf8f6f0"></div>
			</div>
			<div class="f1">
				<div class="mb20 pr20">
					<div class="mb10 c6">订单提醒</div>
					<div class="df br5 fww bcf8f6f0">
						<div class="p10 " style="width: 33%; min-width: 70px;" v-for="reminder in data.reminder" :key="reminder.name">
							<h5 class="c6 mb5">
								{{reminder.name}}
								<el-tooltip v-if="reminder.tooltip">
									<div slot="content" v-html="reminder.tooltip"></div>
									<i class="el-icon-question ch"></i>
								</el-tooltip>
							</h5>
							<p class="fs12"><b class="fs18 cb7a06b">{{reminder.value}}</b> 单</p>
						</div>
					</div>
				</div>
				<div class="mb20 pr20">
					<div class="mb10 c6">产品库存</div>
					<div class="p10 br5 bcf8f6f0 f1">
						<div class="p5 df aic jcsb" v-for="goods in data.goods" :key="goods.title">
							<h5 class="c6">{{goods.title}}</h5>
							<p class="fs12"><b class="fs18 cb7a06b">{{goods.stock}}</b></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import * as echarts from 'echarts';
	import chinaFull from "@/utils/chinaFull.json";
	import mainTableA from '@/components/main-table-a';
	import { mapState, mapMutations } from 'vuex';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_permissions']),
			workbenchs () {
				const workbenchs = [];
				for (let p of this.admin_permissions) {
					if (p.path === '/') {
						workbenchs.push(p);
					}
				}
				return workbenchs;
			}
		},
		data() {
			return {
				timer: null,
				charts: [],
				params: {
					action: 'oms'
				},
				data: {}
			}
		},
		async mounted() {
			if (!this.$utils.checkAuth(this.$api.URI_HOME, this.params.action)) {
				return this.setWorkBench(this.workbenchs[0].action);
			}
			await this.getHome();
			this.timer = setInterval(() => { this.getHome(); }, 4000);
			this.initChart();
			window.addEventListener('resize', this.initChart);
		},
		methods: {
			...mapMutations(['setWorkBench']),
			setElementHeight (e, s = 1.4) {
				// 获取元素的宽度
				const { offsetWidth } = this.$refs[e];
				// 设置元素的高度
				this.$refs[e].style.height = `${offsetWidth / s}px`;
			},
			initChart () {
				this.setElementHeight('registerMap');
				this.setElementHeight('parstMap', 1);
				this.setElementHeight('partMap', 1);
				this.initMap('registerMap');
				const { pie } = this.data;
				this.initPie('parstMap', '产品占比', pie.goods || []);
				this.initPie('partMap', '平台占比', pie.platforms || []);
				this.charts['registerMap'].resize();
			},
			initMap (e) {
				// 注册地图数据
				echarts.registerMap('china', chinaFull);
				// 初始化 ECharts 实例并设置配置项
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					visualMap: {
						min: 0,
						max: 2000,
						left: 'left',
						top: 'bottom',
						text: ['高','低'],
						calculable: true
					},
					series: [
						{
							type: 'map',
							map: 'china',
							layoutCenter: ['50%', '70%'],
							layoutSize: '136%',
						}
					]
				};
				this.charts[e].setOption(option);
			},
			initPie (e, text = '部门占比', data = []) {
				this.charts[e] = echarts.init(this.$refs[e]);
				var option = {
					title: {
						text,
						left: 'center',
					},
					tooltip: {
						trigger: 'item'
					},
					series: [
						{
							type: 'pie',
							radius: '60%',
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							data
						}
					]
				};
				option && this.charts[e].setOption(option);
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
			window.removeEventListener('resize', this.initChart);
			if (this.charts['registerMap']) {
				this.charts['registerMap'].dispose();
			}
		}
	}
</script>