<template>
	<main-table-a class="oa navbar">
		<div slot="table" class="f1 df fdc">
			<div class="df f1 aifs">
				<div class="f2 mb20 pr20">
					<div class="mb10 df jcsb aife">
						<div class="c6">收支概况</div>
						<el-date-picker size="mini" type="daterange" v-model="params.date" :picker-options="$config.DATE_PICKER" :editable="false" :clearable="false" @change="getHome" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</div>
					<a-chart class="br5 mb20 bcf8f6f0"  style="width: 100%; height: 440px;" :dddd="data.price"></a-chart>
					<div class="df fww f1 oh" ref="pies">
						<e-chart class="f1" :style="'height: '+height+'px;'" v-for="(pie, p) in data.pie" :dddd="pie" :key="p"></e-chart>
					</div>
				</div>
				<div class="f1">
					<div class="mb20">
						<div class="mb10 c6">待办提醒</div>
						<div class="br5 p10 pb5 bcf8f6f0">
							<div class="mb20" v-for="reminder in data.reminder" :key="reminder.name">
								<div class="mb5 c6">{{reminder.name}}</div>
								<div class="df jcsb">
									<div class="p10 bce1d6ba" style="width: 31%;" v-for="item in reminder.item" :key="item.name">
										<div class="df aic jcsb">
											<h5 class="c6 mb5">{{item.name}}</h5>
											<p class="fs12"><b class="cb7a06b">{{item.value}}</b> 份</p>
										</div>	
										<p class="fs12"><b class="fs18 cb7a06b">{{item.price}}</b> 元</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mb20">
						<div class="mb10 df jcsb aife">
							<div class="c6">薪酬情况</div>
							<el-date-picker size="mini" style="width: 140px;" v-model="params.month" :picker-options="pickerOptions" @change="getHome" format="yyyy年MM月" value-format="yyyy-MM-01" :editable="false" :clearable="false" type="month" placeholder="选择月份"></el-date-picker>
						</div>
						<div class="br5 bcf8f6f0">
							<div class="df mb10 fww bcf8ebcc" v-if="data.salaries.item">
								<template v-for="item in data.salaries.item">
									<div class="p10" style="width: 33%; min-width: 70px;" :key="item.name">
										<h5 class="c6 mb5">
											{{item.name}}
											<el-tooltip v-if="item.tooltip">
												<div slot="content" v-html="item.tooltip"></div>
												<i class="el-icon-question ch"></i>
											</el-tooltip>
										</h5>
										<p class="fs12">
											<b class="fs18 cb7a06b">{{item.value}}</b> {{item.unit}}
											<span class="c409eff cp" v-if="item.name == '待签名' && $utils.checkAuth($api.URI_SALARIES, 'inform') && item.value" @click="inform">通知</span>
										</p>
									</div>
								</template>
							</div>
							<e-chart :style="'height: '+height+'px;'" :dddd="data.salaries.pie"></e-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>


<script>
	import moment from 'moment';
	import eChart from './chart';
	import aChart from './chart-a';
	import mainTableA from '@/components/main-table-a';
	import { mapState, mapMutations } from 'vuex';

	export default {
		components: {
			eChart,
			aChart,
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_permissions']),
			last_month () {
				const { month } = this.params;
				return moment().subtract(1, 'months').format('yyyy-MM-01') === moment(month).format('yyyy-MM-01');
			},
			workbenchs () {
				const workbenchs = [];
				for (let p of this.admin_permissions) {
					if (p.path === '/') {
						workbenchs.push(p);
					}
				}
				return workbenchs;
			}
		},
		data() {
			return {
				timer: null,
				echart: null,
				height: 300,
				params: {
					action: 'fms',
					month: new Date(moment().subtract(1, 'months')),
					date: [new Date(moment().subtract(1, 'months')), new Date()]
				},
				data: {},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于等于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth >= currentMonth);
					}
				}
			}
		},
		async mounted() {
			if (!this.$utils.checkAuth(this.$api.URI_HOME, this.params.action)) {
				return this.setWorkBench(this.workbenchs[0].action);
			}
			await this.getHome();
			this.timer = setInterval(() => { this.getHome(); }, 4000);
			this.initHeight();
			window.addEventListener('resize', this.initHeight);
		},
		methods: {
			...mapMutations(['setWorkBench']),
			initHeight () {
				const { offsetWidth, offsetHeight } = this.$refs['pies'];
				const { pie } = this.data;
				if ((offsetWidth/pie.length) < offsetHeight) {
					this.height = offsetWidth/pie.length;
				} else {
					this.height = offsetHeight;
				}
			},
			async getHome () {
				const res = await this.$http.get(this.$api.URI_HOME, {params: this.params});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.data = result;
			},
			async getSalaries (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES, { params });
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
			},
			inform () {
				this.$confirm('此操作通过 OA 发起通知', '薪酬签名确认提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.getSalaries({action: 'inform'});
				});
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
			window.removeEventListener('resize', this.initHeight);
		}
	}
</script>